<template>
  <div id="ai-services_nlp_sentiment-analysis">
    <service-template
      :class="{'nlp-example-rtl': currentLanguage===$consts.lang.AR}"
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :run-disabled="runDisabled"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/SEM.svg')"
      service="nlp"
    >
      <template v-slot:header>
        {{ $t('services.sentimentAnalysis.header') }}
      </template>
      <template v-slot:left>
        <input-language
          :step="1"
          :languages="availableLanguages"
          :current-language.sync="currentLanguage"
          :loading="loading"
        />
        <example-text
          :step="2"
          :items="textExamples"
          :current-example.sync="currentExample"
          :example-text.sync="exampleText"
          :loading="loading"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="3"
          :service-name="$t('services.sentimentAnalysis.header')"
          :loading="loading"
        >
          <template v-if="result">
            <overall-sentiment :sentiment="result['doc_sentiment']" />
            <polarity-chart
              :sentiment-result="result"
              :title="$t('services.sentimentAnalysis.sentimentDistribution')"
            />
          </template>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import InputLanguage from '@/views/pages/AIServices/components/InputLanguage/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import ExampleText from '@/views/pages/AIServices/components/ExampleText/Index.vue';
import PolarityChart from '@/views/pages/AIServices/NLP/SentimentAnalysis/PolarityChart/Index.vue';
import OverallSentiment from '@/views/pages/AIServices/NLP/SentimentAnalysis/OverallSentiment/Index.vue';
import { constants } from '@/libs/constants';

export default {
  name: 'SentimentAnalysis',
  components: {
    OverallSentiment,
    PolarityChart,
    ExampleText,
    GeneratedResult,
    InputLanguage,
    ServiceTemplate,
  },
  data: () => ({
    availableLanguages: [constants.lang.EN, constants.lang.AR],
    currentLanguage: constants.lang.EN,
    currentExample: null,
    examples: [],
    exampleText: '',
    result: null,
    loading: false,
  }),
  computed: {
    textExamples() {
      return (this.examples[this.currentLanguage] || []).map((el, index) => ({
        id: index,
        value: el,
      }));
    },
    runDisabled() {
      return !this.exampleText || !this.currentLanguage;
    },
    chartData() {
      return (this.result || []).map(({ topic, score }) => ({
        name: topic,
        percentage: Math.round(score * 10000) / 100,
      })).sort((x, y) => y.percentage - x.percentage);
    },
  },
  watch: {
    currentLanguage() {
      this.currentExample = 0;
      this.result = null;
    },
    currentExample() {
      this.result = null;
    },
    exampleText() {
      this.result = null;
    },
  },
  async created() {
    this.examples = await import('@/../public/examples/NLP/sentimentAnalysis.json');
    this.currentExample = 0;
  },
  methods: {
    async run() {
      this.loading = true;
      return this.$api.nlp.getSentimentAnalysis({
        lang: this.currentLanguage,
        text: this.exampleText,
      });
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result.data;
    },
    runError() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  #ai-services_nlp_sentiment-analysis {
    background: $background-secondary;
  }
</style>
