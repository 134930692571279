<template>
  <div>
    <div
      v-if="title"
      class="title"
    >
      {{ title }}
    </div>
    <div class="polarity-chart">
      <v-chart :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import VChart from 'vue-echarts';
import 'echarts/lib/chart/pie';

const POSITIVE_COLOR = '#59D5AA';
const NEUTRAL_COLOR = '#FFC42D';
const NEGATIVE_COLOR = '#F54A4A';

export default {
  name: 'PolarityChart',
  components: {
    VChart,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    sentimentResult: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        legend: {
          show: false,
        },
        label: {
          rich: {
            positive: {
              align: 'left',
              backgroundColor: {
                image: `${this.$consts.publicPath}/polarity/positive-icon.svg`,
              },
              verticalAlign: 'top',
              lineHeight: 15,
              height: 40,
              width: 40,
            },
            neutral: {
              align: 'left',
              backgroundColor: {
                image: `${this.$consts.publicPath}/polarity/neutral-icon.svg`,
              },
              verticalAlign: 'top',
              lineHeight: 15,
              height: 40,
              width: 40,
            },
            negative: {
              align: 'left',
              backgroundColor: {
                image: `${this.$consts.publicPath}/polarity/negative-icon.svg`,
              },
              verticalAlign: 'top',
              lineHeight: 15,
              height: 40,
              width: 40,
            },
            spacing: {
              align: 'left',
              width: 40,
              lineHeight: 0,
            },
            title: {
              verticalAlign: 'top',
              align: 'left',
              padding: [0, 10, 0, 10],
              fontFamily: 'Montserrat',
              lineHeight: 15,
              fontSize: 12,
            },
            percentage: {
              verticalAlign: 'top',
              align: 'left',
              padding: [0, 10, 0, 10],
              fontFamily: 'Montserrat',
              fontWeight: 800,
              fontSize: 18,
              lineHeight: 22,
            },
          },
        },
        labelLayout(params) {
          const isLeft = true;
          const points = params.labelLinePoints;
          // Update the end point.
          points[2][0] = isLeft
            ? params.labelRect.x
            : params.labelRect.x + params.labelRect.width;

          return {
            labelLinePoints: points,
          };
        },
        series: this.sentimentResult && [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            data: [
              this.sentimentResult.positive_percentage && {
                value: this.sentimentResult.positive_percentage,
                name: this.$t('services.polarity.positive'),
                itemStyle: {
                  color: POSITIVE_COLOR,
                },
                label: {
                  formatter: ({ value }) => `{positive|}{title|Positive}\n{spacing|}{percentage|${Math.round(value * 10000) / 100}%}`,
                },
              },
              this.sentimentResult.neutral_percentage && {
                value: this.sentimentResult.neutral_percentage,
                name: this.$t('services.polarity.neutral'),
                itemStyle: {
                  color: NEUTRAL_COLOR,
                },
                label: {
                  formatter: ({ value }) => `{neutral|}{title|Neutral}\n{spacing|}{percentage|${Math.round(value * 10000) / 100}%}`,
                },
              },
              this.sentimentResult.negative_percentage && {
                value: this.sentimentResult.negative_percentage,
                name: this.$t('services.polarity.negative'),
                itemStyle: {
                  color: NEGATIVE_COLOR,
                },
                label: {
                  formatter: ({ value }) => `{negative|}{title|Negative}\n{spacing|}{percentage|${Math.round(value * 10000) / 100}%}`,
                },
              },
            ].filter((el) => el),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    padding: 0.16rem 0.18rem 0;
    font-weight: 600;
    font-size: 0.14rem;
    line-height: 0.17rem;
  }

  .polarity-chart {
    position: relative;
    width: 100%;
    padding-top: 50%;

    /deep/ .echarts {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
