<template>
  <div class="overall-sentiment">
    <div class="heading">
      {{ $t('services.sentimentAnalysis.overallSentiment') }}
    </div>
    <div class="legend">
      <span>-1</span>
      <span>0</span>
      <span>+1</span>
    </div>
    <div class="bar">
      <div class="gradient" />
      <div class="point negative" />
      <div class="point neutral" />
      <div class="point positive" />
    </div>
    <div class="tooltip-container">
      <div
        :style="{ left: `${(sentiment * 100 + 100) / 2}%` }"
        class="tooltip"
      >
        <div class="tooltip-text">
          {{ sentiment > 0 ? '+' : '' }}{{ parseFloat(sentiment).toFixed(2) }}
        </div>
      </div>
    </div>
    <div class="legend">
      <span>{{ $t('services.polarity.negative') }}</span>
      <span>{{ $t('services.polarity.neutral') }}</span>
      <span>{{ $t('services.polarity.positive') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverallSentiment',
  props: {
    sentiment: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  $negative-color: #F54A4A;
  $neutral-color: #FFC42D;
  $positive-color: #59D4AA;
  $tooltip-color: #475266;

  .overall-sentiment {
    padding: 0.08rem 0.18rem 0.2rem;
    background-color: rgba($color-white, 0.6);

    .heading {
      margin-bottom: 0.26rem;
      font-weight: 600;
      font-size: 0.14rem;
      line-height: 0.17rem;
    }

    .legend {
      display: flex;
      justify-content: space-between;
      color: $text-primary-dimmed;
      font-weight: 400;
      font-size: 0.1rem;
      line-height: 0.14rem;
    }

    .tooltip {
      position: absolute;
      top: -0.4rem;
      transform: translateX(-50%);

      &-container {
        position: relative;
        margin: 0 0.04rem;
      }

      &-text {
        position: relative;
        padding: 0.02rem 0.09rem 0.03rem;
        border-radius: 0.03rem;
        background-color: $tooltip-color;
        color: $color-white;
        font-weight: 600;
        font-size: 0.12rem;
        line-height: 0.15rem;

        &:before {
          z-index: 1;
          display: block;
          content: '';
          position: absolute;
          bottom: -0.14rem;
          left: 50%;
          width: 0.08rem;
          height: 0.04rem;
          background: rgba($color-white, 0.4);
          transform: translateX(-50%);
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          bottom: -0.06rem;
          left: 50%;
          width: 0.09rem;
          height: 0.06rem;
          background: url('~@/assets/images/icons/tip.svg') center no-repeat;
          transform: translateX(-50%);
        }
      }
    }

    .bar {
      $dot-size: 0.08rem;

      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-top: 0.03rem;
      margin-bottom: 0.04rem;
      height: $dot-size;

      .gradient {
        position: absolute;
        top: 0.02rem;
        bottom: 0.02rem;
        left: 0.04rem;
        right: 0.04rem;
        background: linear-gradient(270deg, #59D4AA 4.49%, #59D4AA 29.11%, #FFC42D 36.46%, #FFC42D 59.08%, #F76544 83.4%, #F54A4A 98.2%);
      }

      .point {
        z-index: 1;
        height: $dot-size;
        width: $dot-size;
        border-radius: 50%;

        &.negative {
          background-color: $negative-color;
        }

        &.neutral {
          background-color: $neutral-color;
        }

        &.positive {
          background-color: $positive-color;
        }
      }
    }
  }
</style>
